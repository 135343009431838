/*
 * =============================================================================
 * Calibre
 * =============================================================================
 */

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-Thin.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-ThinItalic.otf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-LightItalic.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-RegularItalic.otf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-MediumItalic.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-Semibold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-SemiboldItalic.otf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-BoldItalic.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-Black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('./assets/fonts/calibre/Calibre-BlackItalic.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
